import React from 'react'
import { useNavigate } from 'react-router-dom';

const Logo = () => {
  
  const baseUrl = process.env.REACT_APP_BASE_URL_PROD;
  const navigate = useNavigate();

  return (
    <div className="logo cursor-pointer mb-4 xl:text-3xl xl:absolute relative flex flex-row items-end justify-center text-t-dark text-5xl font-bold" onClick={() => navigate('/')}>
        <img
          src={`${baseUrl}timely_logo.svg`}
          // src='../timely_logo.svg'
          className='w-20 h-20'
        />
        Timely
    </div>
  )
}

export default Logo