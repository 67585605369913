import React, { useEffect, useState } from 'react'
import apiClient from '../helper/apiClient';
import { useNavigate } from 'react-router-dom';

const UserHomepage = ({ identifier, template }) => {

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const divClass = `container shadow-lg sm:rounded-lg rounded-none mt-8 w-full p-10 border-2`
    // const divClass = "container shadow sm:rounded-lg rounded-none my-4 w-full bg-gradient-to-tl from-t-light/20 via-t-light/60 to-t-light/20 px-10 py-10"
    // const divClass = "container sm:rounded-lg rounded-none my-4 md:w-4/6 sm:w-5/6 bg-gradient-to-tl from-t-light/20 via-t-light/60 to-t-light/20 px-10 py-10"
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [fields, setFields] = useState(null);


    useEffect(() => {

        const getFields = async () => {
        try {
            const response = await apiClient.get(`/api/fields`, {
                params: { identifier }, // Use params to construct the query string
            });
    
            // Assuming you want to handle 404 status here
            if (response.status === 404) {
                navigate('/register?id=' + identifier);
                return;
            }
    
            // Set the fields data from the response
            setFields(response.data);
        } catch (error) {
            // Handle error response specifically
            if (error.response) {
                console.error('Error fetching user fields:', error.response);
                // Optionally handle specific status codes if needed
            } else {
                console.error('Error fetching user fields:', error);
            }
        }
    };
            
    getFields()
    setLoading(false);

    }, [identifier]);

    if (loading) {
        return <div>Loading...</div>;
    }


    return (
        <>
            
            {(fields?.image || fields?.title) && (
  <div
    className={`
      relative flex items-center md:flex-row w-full p-10 flex-col min-h-120 bg-[--secondary-color]
    `}
    style={{'--secondary-color': template?.secondary_color}}

  >
    {/* Background overlay */}
    {/* <div
        className={`
            absolute inset-0 opacity-100
            bg-gradient-to-tl from-[var(--secondary-color)] via-[var(--primary-color)] to-[var(--secondary-color)]
        `}
        style={{
            '--primary-color': template?.secondary_color || '#ffffff', // Default to white if undefined
            '--secondary-color': template?.secondary_color || '#000000', // Default to black if undefined  
            zIndex: 0,
        }}
    ></div> */}

    {/* Content */}
    <div className="relative flex flex-col md:flex-row w-full items-center">
      {fields?.image && (
        <div
          className={`${
            fields?.title && 'mb-4'
          } my-0 mx-auto rounded-xl min-w-56 min-h-56 w-56 h-56 bg-cover bg-center`}
          style={{ backgroundImage: `url(${apiBaseUrl + fields?.image})` }}
        />
      )}
      <div className="flex flex-col text-left md:ml-8 p-8 bg-white/80 rounded-xl">
        {fields?.title && (
          <div className="text-center md:text-left text-4xl font-bold mb-8">
            {fields?.title}
          </div>
        )}
        {fields?.shortion && (
          <div className="text-center md:text-left text-base">
            {fields?.shortion}
          </div>
        )}
        <button style={{ '--hover-color': template?.secondary_color, '--bgd-color': template?.primary_color}}
            className="btn text-base btn-outline w-fit hover:bg-[--hover-color] bg-[--bgd-color] mt-8"
            onClick={() => navigate(`/${identifier}/events`, { replace: true })}
        >
            Időpontfoglalás
        </button>
      </div>
    </div>
  </div>
)}


            {fields?.introduction &&

            
            // <div className={`${divClass} ql text-lg`} style={{background: template?.secondary_color}}>
            <div
                className={`border border-[--dark-color] container shadow-6xl sm:rounded-lg rounded-none mt-8 w-full p-10 ql text-lg`}
                style={{background: template?.secondary_color, '--dark-color': template?.dark_color}}
            >
                {fields?.introduction && <div dangerouslySetInnerHTML={{ __html: fields?.introduction }} />}
            </div>
            }
        </>
    )
}

export default UserHomepage