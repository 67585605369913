import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // Import js-cookie to handle cookies
import { useParams } from 'react-router-dom';
import '../App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiClient from '../helper/apiClient';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

function AdminNewPassword() {

    const navigate = useNavigate();
    const { identifier, forgotpasswordid } = useParams();

    const [loading, setLoading] = useState(false);
    const [expired, setExpired] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [passwordCriteria, setPasswordCriteria] = useState(
        {
            length: false,
            uppercase: false,
            number: false,
            specialChar: false
        }
    );

    const labelClass = "input input-bordered rounded-xl border-2 flex items-center gap-2 border-t-dark"
    const divClass = "container my-4 lg:w-3/6 md:w-4/6 sm:w-5/6 bg-gradient-to-tl from-t-light/20 via-t-light/60 to-t-light/20 px-10 py-10 rounded-lg"

    useEffect(() => {
        const fetchForgotPasswordStatus = async () => {
            const uuid = Cookies.get('uuid');

            if (uuid) {
                navigate(`/${identifier}/admin`, { replace: true });
                return; // Stop further execution if user is already logged in
            }

            const isExpired = await checkForgotPasswordId();
            setExpired(isExpired);
            setLoading(false);
        };

        fetchForgotPasswordStatus();
    }, []);

    const checkForgotPasswordId = async () => {
        try {
            const response = await apiClient.get(`/api/check/forgot-password-id`, {
                params: { identifier, forgotpasswordid },
            });
            
            return response.data?.expired ?? false;
        } catch (error) {
            console.error('Error checking forgot password id:', error);
            return false; // Return false in case of error
        }
    };

      const isError = async () => {
        let hasError = false;

        if(!isPasswordMatch) {
            if(!hasError) {
                toast.error('A két jelszónak egyeznie kell.');
            }
            hasError = true;
        }

        if(!passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) {
            if(!hasError) {
                toast.error('A jelszónak biztonságosnak kell lennie.');
            }
            hasError = true;
        }

        return hasError;
    };
    

    const handleNewPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            if (await isError()) {
                scrollToTop();
                setLoading(false);
                return;
            }
    
            const userData = { password, identifier, forgotpasswordid };
            await apiClient.post(`/api/new-password`, userData);
    
            toast.success('Sikeresen megváltoztattad a jelszavadat, jelentkezz be.');
            setPassword('');
            setPasswordConfirmation('');
            setTimeout(() => {
                navigate(`/${identifier}/admin`, { replace: true });
            }, 2800);

        } catch (error) {
            handleNewPasswordError(error);
        } finally {
            setLoading(false);
        }
    };
    
    const handleNewPasswordError = (error) => {
        if (error.response?.status === 401) {
            toast.error('Hibás azonosító vagy jelszó.');
        } else if (error.code === 'ECONNABORTED') {
            toast.error('A szerver nem válaszolt időben.');
        } else {
            toast.error('Ismeretlen hiba történt.');
        }
    };
    

    const handleBackToLogin = () => {
        navigate(`/${identifier}/forgotpassword`, { replace: true });
    }

    const scrollToTop = () => {
        if(window !== undefined) {
            window.scrollTo({
            top: 0,
            behavior: 'smooth',
            });
        }
    };

    const handlePasswordChange = (value, isConfirmation = false) => {
        if (!isConfirmation) {
            setPassword(value);
        } else {
            setPasswordConfirmation(value);
        }
        setIsPasswordMatch(!isConfirmation ? value === passwordConfirmation : password === value);
        checkPasswordCriterias(value);
    };

    const checkPasswordCriterias = (value) => {
        const criterias = {
            length: value.length >= 9,
            uppercase: /[A-Z]/.test(value),
            number: /\d/.test(value),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value)
        }
        setPasswordCriteria(criterias)
    };
  
    return (
    <>
    <div className='admin-login'>
        <div className={`${divClass}`}>
            <h1 className="text-center text-t-dark">{identifier.charAt(0).toUpperCase() + identifier.slice(1)} új jelszó</h1>
        </div>
        <div className={`${divClass}`}>
            {expired &&
            <>
                <div className='text-base mb-8'>Ez a link lejárt.</div>
                <button className="btn bg-t-green hover:bg-t-blue text-lg text-t-dark" onClick={handleBackToLogin}>Kérek egy új linket.</button>

                {/* <div className="cursor-pointer w-fit text-base underline hover:no-underline" onClick={handleBackToLogin}>Kérek egy új linket.</div> */}
            </>
            }

            {!expired &&
            <>
                <div className='text-base mb-8'>Kérjük, add meg az új jelszavadat.</div>
                <form onSubmit={handleNewPassword} className="form-control gap-4">
                    <label className={`${labelClass} ${(!isPasswordMatch || !passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) ? 'border-red-500' : ''}`}>
                        Jelszó
                        {/* {(!isPasswordMatch || !passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) && <span className="indicator-item badge badge-sm bg-red-500 text-t-light">!</span>} */}
                        <input type="password" className="grow"
                            placeholder="********"
                            value={password} 
                            onFocus={(e) => {
                                handlePasswordChange(e.target.value);
                            }}
                            onBlur={(e) => {
                                handlePasswordChange(e.target.value);
                            }}
                            onChange={(e) => {
                                handlePasswordChange(e.target.value);
                            }}
                        />
                    </label>
                    <label className={`${labelClass} ${(!isPasswordMatch || !passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) ? 'border-red-500' : ''}`}>
                        Jelszó megerősítése
                        {/* {(!isPasswordMatch || !passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) && <span className="indicator-item badge badge-sm bg-red-500 text-t-light">!</span>} */}
                        <input type="password" className="grow"
                            placeholder="********"
                            value={passwordConfirmation}
                            onFocus={(e) => {
                                handlePasswordChange(e.target.value, true);
                            }}
                            onBlur={(e) => {
                                handlePasswordChange(e.target.value, true);
                            }}
                            onChange={(e) => {
                                handlePasswordChange(e.target.value, true);
                            }}
                        />
                    </label>
                    {!loading && <>
                        {/* {(!passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) && */}
                        <div className="criterias text-left mx-6">
                            <div className="criterias-title">Az jelszavadnak tartalmaznia kell:</div>
                            <ul>
                                {<li className={!passwordCriteria.length ? `list-disc` : `list-image-checkmark`}>legalább 9 karaktert</li>}
                                {<li className={!passwordCriteria.uppercase ? `list-disc` : `list-image-checkmark`}>kis- és nagybetűt</li>}
                                {<li className={(!passwordCriteria.number || !passwordCriteria.specialChar) ? `list-disc` : `list-image-checkmark`}>minimum egy számot és egy speciális karaktert</li>}
                            </ul>
                        </div>
                        {/* } */}
                    </>}
                    
                    {loading ?
                        <button className="btn bg-t-green">
                            <span className="loading loading-spinner"></span>
                        </button>
                    :  
                        <button type="submit" className="btn bg-t-green hover:bg-t-blue text-lg text-t-dark">Mentés</button>
                    }
                    {/* <div className="cursor-pointer w-fit text-sm underline hover:no-underline" onClick={handleBackToLogin}>Vissza a bejelentkezéshez.</div> */}
                </form>
            </>}
        </div>
    </div>
    <Footer />
    </>
    );
}

export default AdminNewPassword;
