import React, { useState, useEffect } from "react";
import RangeInput from "./RangeInput";
import { convertMinutesToTime, convertTimeToMinutes } from "../../helper/timeConverters";

const WorkPlanRanges = ({ toast, userId, dayName, selectedDate, workPlanRanges, originalWorkPlanRanges, setOpenWorkPlanModal, setWorkPlanRanges, setOriginalWorkPlanRanges }) => {
    
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const [isOpen, setIsOpen] = useState(true);

    const getRangeIds = (workPlanRanges) => workPlanRanges?.map((range) => range.id);

    const updateUserAvailableRanges = async () => {
        try {
            // TO DO: it cannot be that two ranges collide MAYBE HERE
            if(!checkRangeCollisions()) {
                toast.error(
                    'Az új munkaidő ütközik egy már meglévővel.',
                    {
                        autoClose: 6000
                    }
                );
                return
            }

            if(!checkRangesIntegrity()) {
                toast.error(
                    'Az munkaidő vége nem lehet korábbi mint a kezdete.',
                    {
                        autoClose: 6000
                    }
                );
                return
            }

            // if(workPlanRanges.length === 0) {
            //     toast.info('Nincs mit elmenteni.');
            //     return
            // }

            const workPlanRangeIds = getRangeIds(workPlanRanges);
            const originalWorkPlanRangeIds = getRangeIds(originalWorkPlanRanges);
            const deletableIds = originalWorkPlanRangeIds.filter(id => !workPlanRangeIds.includes(id));

            const response = await fetch(`${apiBaseUrl}api/workplan/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
                },
                body: JSON.stringify({ ranges: workPlanRanges, userId, deletableIds }),
            });

            const result = await response.json();
            if (response.ok) {
                setOriginalWorkPlanRanges(workPlanRanges);
                toast.success('Sikeres mentés.');
            } else {
                toast.error('Hiba történt.');
            }
        } catch (error) {
            toast.error('Hiba történt.');
        }
    };

    const checkRangesIntegrity = () => {
        // Check for integrity
        for (let range of workPlanRanges) {
            let startRangeTime = convertTimeToMinutes(range.start_hour, range.start_minute);
            let endRangeTime = convertTimeToMinutes(range.end_hour, range.end_minute);

            if ((startRangeTime >= endRangeTime)) {
                return false;
            }
        }
        return true;
    }
    
    const checkRangeCollisions = () => {
        // Check for collisions
        for (let range of workPlanRanges) {
            let startRangeTime = convertTimeToMinutes(range.start_hour, range.start_minute);
            let endRangeTime = convertTimeToMinutes(range.end_hour, range.end_minute);

            for (let otherRange of workPlanRanges) {
                // Skip the range being updated
                if (range.id === otherRange.id) continue;

                let startOtherRangeTime = convertTimeToMinutes(otherRange.start_hour, otherRange.start_minute);
                let endOtherRangeTime = convertTimeToMinutes(otherRange.end_hour, otherRange.end_minute);

                // Check if the other range overlaps with the current range
                if ((startRangeTime < endOtherRangeTime && endRangeTime > startOtherRangeTime)) {
                    return false;
                }
            }
        }
        return true;
    }


    const handleClose = () => {
        setIsOpen(!isOpen);
        setOpenWorkPlanModal(false);
    }

    const addRange = () => {
        setWorkPlanRanges([...workPlanRanges, { id: Date.now(), work_date: selectedDate,
        start_hour: null, start_minute: null, end_hour: null, end_minute: null }]);
    };

    const removeRange = (id) => {
        setWorkPlanRanges(workPlanRanges?.filter(range => range.id !== id));
    };

    const updateRange = (id, newRange) => {
        setWorkPlanRanges(workPlanRanges?.map(range => range.id === id ? newRange : range));
    };
    

    return (
    <>
    <input type="checkbox" id="work_plan_modal" className="modal-toggle" defaultChecked={isOpen} />
    <div className="modal" role="dialog">
    <div className="modal-box bg-t-light w-10/12 max-w-5xl">

        <div>
            <div className="mb-8">
                <h2 className="text-center">Mikor dolgozol ezen a napon?</h2>
                <p className="text-center text-xl">{`${selectedDate} ${dayName}`}</p>
            </div>
            
            {workPlanRanges?.length !== 0 ?
                workPlanRanges?.map(range => (
                    <RangeInput
                        key={range.id}
                        range={range}
                        selectedDate={selectedDate}
                        removeRange={removeRange}
                        updateRange={updateRange}
                    />
            ))
            :
                <p className="text-center text-lg">Még nem adtál meg munkaidőt erre a napra.</p>
            }
            <div className='flex mt-4 w-full items-center justify-end'>
                <div
                    className='h-9 min-h-0 btn bg-t-green btn-outline hover:text-t-light text-t-dark ml-2 align-middle text-4xl p-2 leading-11 font-normal'
                    onClick={addRange}
                >
                +
                </div>
            </div>
        </div>

        <div className="modal-action">
            <button className="btn bg-t-pink btn-outline mt-4 hover:bg-t-pink/75" onClick={() => handleClose()}>Vissza</button>
            <button className="btn bg-t-green btn-outline mt-4 hover:bg-t-green/75" onClick={() => updateUserAvailableRanges()}>Mentés</button>
        </div>
    </div>
    </div>
    </>
  );
};

export default WorkPlanRanges;