import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // Import js-cookie to handle cookies
import { useParams } from 'react-router-dom';
import '../App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiClient from '../helper/apiClient';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

function AdminForgotPassword() {

    const navigate = useNavigate();
    const { identifier } = useParams();

    const [email, setEmail] = useState('');
    const [inputErrors, setInputErrors] = useState(
        {
            email: false,
        }
    );

    const [loading, setLoading] = useState(false);

    const labelClass = "input input-bordered rounded-xl border-2 flex items-center gap-2 border-t-dark"
    const divClass = "container my-4 lg:w-3/6 md:w-4/6 sm:w-5/6 bg-gradient-to-tl from-t-light/20 via-t-light/60 to-t-light/20 px-10 py-10 rounded-lg"

    useEffect(() => {
        const uuid = Cookies.get('uuid');
        
        if (uuid) {
            navigate(`/${identifier}/admin`, { replace: true });
        }
        setLoading(false);
      }, []);

    const isError = async () => {
        let hasError = false;

        if (!email) {
            if(!hasError) {
                toast.error('Meg kell adnod az e-mail címed.');
            }
            setInputErrors(prevState => ({
                ...prevState,
                email: true,
            }));
            hasError = true;
        }

        return hasError;
    };
    

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setInputErrors({ email: false });
        setLoading(true);
    
        try {
            if (await isError()) {
                scrollToTop();
                return;
            }
    
            const userData = { email, identifier };
            await apiClient.post(`/api/forgot-password`, userData);
    
            toast.success('Ha az e-mail cím regisztrálva van, hamarosan kapni fogsz egy visszaigazoló e-mailt.', 
                {
                    autoClose: 6000
                }
            );
        } catch (error) {
            handleForgotPasswordError(error);
        } finally {
            setLoading(false);
        }
    };
    
    const handleForgotPasswordError = (error) => {
        if (error.response?.status === 401) {
            toast.error('Hibás e-mail vagy jelszó.');
        } else if (error.code === 'ECONNABORTED') {
            toast.error('A szerver nem válaszolt időben.');
        } else {
            toast.error('Ismeretlen hiba történt.');
        }
    };
    

    const handleBackToLogin = () => {
        navigate(`/${identifier}/admin`, { replace: true });
    }

    const scrollToTop = () => {
        if(window !== undefined) {
            window.scrollTo({
            top: 0,
            behavior: 'smooth',
            });
        }
    };
  
    return (
    <>
    <div className='admin-login'>
        <div className={`${divClass}`}>
            <h1 className="text-center text-t-dark">{identifier.charAt(0).toUpperCase() + identifier.slice(1)} elfelejtett jelszó</h1>
        </div>
        <div className={`${divClass}`}>

            <div className='text-base mb-8'>Kérjük, add meg az e-mail címed és küldünk egy linket a jelszó visszaállításához.</div>
            <form onSubmit={handleForgotPassword} className="form-control gap-4">
                <label className={`${labelClass} ${inputErrors.email ? 'border-red-500' : ''}`}>
                    E-mail cím
                    <input type="email" className="grow"
                        placeholder="hello@timely.hu"
                        value={email} 
                        onChange={e => setEmail(e.target.value)} 
                    />
                </label>
                
                {loading ?
                    <button className="btn bg-t-green">
                        <span className="loading loading-spinner"></span>
                    </button>
                :  
                    <button type="submit" className="btn bg-t-green hover:bg-t-blue text-lg text-t-dark">Küldés</button>
                }
                <div className="cursor-pointer w-fit text-sm underline hover:no-underline" onClick={handleBackToLogin}>Vissza a bejelentkezéshez.</div>
            </form>
        </div>
    </div>
    <Footer />
    </>
    );
}

export default AdminForgotPassword;
