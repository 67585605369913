import React, { useState, useEffect } from "react";
import '../App.css';
import FieldEditor from '../components/editor/FieldEditor';
import ProfileImageUploader from '../components/editor/ProfileImageUploader';
import EventEditor from '../components/editor/EventEditor';
import DateEditor from '../components/editor/DateEditor';
import { useLocation } from 'react-router-dom';
import ProfileEditor from "../components/editor/ProfileEditor";
import EmailEditor from "../components/editor/EmailEditor";
import PasswordEditor from "../components/editor/PasswordEditor";
import Calendar from '../components/Calendar';
import NavBar from "../components/NavBar";
import TemplatesBlock from "../components/editor/TemplatesBlock";
import Cookies from 'js-cookie'; // Import js-cookie to handle cookies

function AdminPage({ userId, userFirstName, identifier }) {

    const { search } = useLocation();

    const [page, setPage] = useState("account");
    const [loading, setLoading] = useState(false);

    const handleLogout = () => {
        // Remove the uuid cookie
        Cookies.remove('uuid');
        Cookies.remove('firstname');
        // Reload the page to trigger Admin.js to check login state
        window.location.reload();
    };

    useEffect(() => {
        setLoading(true);
        
        const uuid = Cookies.get('uuid');
        if (!uuid) {
            handleLogout();
        }

        const params = new URLSearchParams(search);
        const urlPage = params.get('p') || 'account';
        setPage(urlPage);
        setLoading(false);

    }, [search]);

    useEffect(() => {
      }, []);

    if (loading) {
        return (
            <div className='flex justify-center min-h-50-screen'>
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        );
    }

    return (
        <div className='admin-page'>
            <NavBar userFirstName={userFirstName} setPage={setPage} />
            <div className='px-4'>
                {page === "account" &&
                <>
                    <ProfileEditor userId={userId} identifier={identifier}/>
                    <EmailEditor userId={userId} identifier={identifier}/>
                    <PasswordEditor userId={userId} identifier={identifier}/>
                </>
                }
                {page === "calendar" &&
                    <>
                        <Calendar userId={userId} identifier={identifier}/>
                    </>
                }
                {page === "introduction" &&
                <>
                    <ProfileImageUploader userId={userId} identifier={identifier}/>
                    <TemplatesBlock userId={userId} identifier={identifier}/>
                    <FieldEditor type='title' userId={userId} identifier={identifier}/>
                    <FieldEditor type='shortion' userId={userId} identifier={identifier}/>
                    <FieldEditor type='introduction' userId={userId} identifier={identifier}/>
                    <FieldEditor type='email' userId={userId} identifier={identifier}/>
                    <FieldEditor type='phone' userId={userId} identifier={identifier}/>
                </>
                }
                {page === "events" &&
                <>
                    <EventEditor type='event' userId={userId} identifier={identifier}/>
                </>
                }
                {page === "workplan" &&
                <>
                    <DateEditor userId={userId} identifier={identifier}/>
                </>
                }

            </div>
        </div>
    );
}

export default AdminPage;
