import React, {useEffect, useState} from 'react';
import '../App.css';
import { useParams } from 'react-router-dom';
import apiClient from '../helper/apiClient';
import UserMenu from '../components/UserMenu';
import Footer from '../components/Footer';
import UserEventBlock from '../components/UserEventBlock';

function UserEventPage() {

  const { identifier, eventId } = useParams();
  const [template, setTemplate] = useState(null);
  const [event, setEvent] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getEvent = async () => {
      try {
          const response = await apiClient.get(`/api/event`, {
              params: { eventId, identifier }, // Use params to construct the query string
          });
          console.log('response.data', response.data)
          setEvent(response.data);
      } catch (error) {
          if (error.response) {
              console.error('Error fetching event:', error.response);
          } else {
              console.error('Error fetching event:', error);
          }
      } finally {
          setLoading(false);
      }
    };
      
    getEvent();
    setLoading(false);

  }, [eventId && identifier]);

  useEffect(() => {

    const getTemplate = async () => {
      try {
          const response = await apiClient.get(`/api/template`, {
            params: { identifier }, // Use params to construct the query string
          });
  
          const data = response.data;
          setTemplate(data);
  
      } catch (error) {
          console.error('Error fetching user template:', error);
      }
  };
      
    getTemplate();
    setLoading(false);

  }, [identifier]);

  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className='user-page pb-40'
        style={{ background: template?.background_color, color: template?.dark_color}}
      >
        <UserMenu template={template} identifier={identifier} />

        {(event && template) && <UserEventBlock template={template} event={event} />}

      </div>
      <Footer template={template} />
    </>
  )
}

export default UserEventPage;