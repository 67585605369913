import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // To manage cookies
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DayPicker } from "react-day-picker";
import { hu } from "react-day-picker/locale";
import "react-day-picker/style.css";
import WorkPlanRanges from './WorkPlanRanges';

function AdminDateEditor({ userId, identifier }) {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  
  const [openWorkPlanModal, setOpenWorkPlanModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDateChanged, setIsDateChanged] = useState(false);
  // const [workPlanRanges, setWorkPlanRanges] = useState([{ id: Date.now(), work_date: '',
  //   start_hour: 0, start_minute: 0, end_hour: 0, end_minute: 0 }]);
  //   const [originalWorkPlanRanges, setOriginalWorkPlanRanges] = useState([{ id: Date.now(), work_date: '',
  //     start_hour: 0, start_minute: 0, end_hour: 0, end_minute: 0 }]);

  const [workPlanRanges, setWorkPlanRanges] = useState([]);
    const [originalWorkPlanRanges, setOriginalWorkPlanRanges] = useState([]);

  const [dayName, setDayName] = useState('');

  const fieldType = "Munkaidőm"
  const selectedOption = "custom"
  const [selectedDays, setSelectedDays] = useState({
    H: true,
    K: true,
    Sze: true,
    Cs: true,
    P: true,
    Szo: true,
    V: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedDays((prevDays) => ({
      ...prevDays,
      [name]: checked,
    }));
  };

  const getDisabledDays = () => {
    if (selectedOption === 'custom') {
      const enabledDays = Object.entries(selectedDays)
        .filter(([, isSelected]) => isSelected)
        .map(([dayCode]) => getDayOfWeek(dayCode));
      
      const allDays = [0, 1, 2, 3, 4, 5, 6];
      const disabledDaysOfWeek = allDays.filter((day) => !enabledDays.includes(day));
      
      return [{ dayOfWeek: disabledDaysOfWeek }, { before: new Date() }];
    }
    return [];
  };

  const getDayOfWeek = (dayCode) => {
    const dayMap = {
      H: 1,
      K: 2,
      Sze: 3,
      Cs: 4,
      P: 5,
      Szo: 6,
      V: 0,
    };
    return dayMap[dayCode];
  };

  const getDayLabel = (dayCode) => {
    const days = {
      H: "Hétfő",
      K: "Kedd",
      Sze: "Szerda",
      Cs: "Csütörtök",
      P: "Péntek",
      Szo: "Szombat",
      V: "Vasárnap",
    };
    return days[dayCode];
  };


  const modifiers = {};
  if (selectedDate) {
    modifiers.selected = selectedDate;
  }

  function toMariaDBTimestamp(date) {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0'); // A hónapokat nulláról indexelik
    const day = String(date?.getDate()).padStart(2, '0');
    // const hours = String(date?.getHours()).padStart(2, '0');
    // const minutes = String(date?.getMinutes()).padStart(2, '0');
    // const seconds = String(date?.getSeconds()).padStart(2, '0');
  
    const daysOfWeek = ["Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"];
    const dayOfWeek = daysOfWeek[date?.getDay()];
    // console.log(`A nap: ${dayOfWeek}`);
    setDayName(dayOfWeek);

    return `${year}-${month}-${day}`;
  }

//   useEffect(() => {
//   console.log('workPlanRanges', workPlanRanges)
// }, [workPlanRanges]);

  useEffect(() => {
      if(identifier) {
        getUserWorkingDays();
      }
  }, [identifier]);

  useEffect(() => {
    if(selectedDate) {
      getWorkPlan();
      setOpenWorkPlanModal(true);
    }
  }, [isDateChanged]);

  const getUserWorkingDays = async () => {

    const userData = {
      identifier,
    };

    fetch(`${apiBaseUrl}api/workingdays`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
      },
      body: JSON.stringify(userData),
    })
    .then(response => {
      if (response.status === 404) {
        // handleLogout();
      }
      return response.json();
    })
    .then(data => {
      if (data) {
        setSelectedDays(JSON.parse(data?.working_days));
      }
      setLoading(false);
    })
    .catch(error => {
      console.error('Error:', error);
      setLoading(false);
    });
  }

  const getWorkPlan = async () => {
    const uuidFromCookie = Cookies.get('uuid');

    const workPlanData = {
      userId: uuidFromCookie,
      identifier,
      selectedDate
    };

    fetch(`${apiBaseUrl}api/workplan/select`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
      },
      body: JSON.stringify(workPlanData),
    })
    .then(response => {
      if (response.status === 404) {
        // handleLogout();
        setWorkPlanRanges([]);
        setOriginalWorkPlanRanges([]);
        // setWorkPlanRanges([{ id: Date.now(), work_date: selectedDate,
        //   start_hour: 0, start_minute: 0, end_hour: 0, end_minute: 0 }]);
        // setOriginalWorkPlanRanges([{ id: Date.now(), work_date: selectedDate,
        //   start_hour: 0, start_minute: 0, end_hour: 0, end_minute: 0 }]);

        return
      }
      return response.json();
    })
    .then(data => {
      if (data) {
        setWorkPlanRanges(data);
        setOriginalWorkPlanRanges(data);
      }
      setLoading(false);
    })
    .catch(error => {
      console.error('Error:', error);
      setLoading(false);
    });
  }

  const handleLogout = () => {
    Cookies.remove('uuid');
    Cookies.remove('firstname');
    window.location.reload();
  };

  const updateUserField = async () => {

    const fieldData = {
      userId,
      identifier,
      selectedDate
    };

    return

    fetch(`${apiBaseUrl}api/dates/update`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
      },
      body: JSON.stringify(fieldData),
    })
    .then(response => {
      if (response.status === 500) {
        handleLogout();
      }
      return response.json();
    })
    .then(data => {
      toast.success('Sikeres mentés.');
      console.log('data', data);
    })
    .catch(error => {
      console.error('Error updating field:', error);
    });
  }

  const handleSelect = (newSelected) => {
    console.log('newSelected', toMariaDBTimestamp(newSelected))
    if(newSelected) {
      setIsDateChanged(!isDateChanged);
      setSelectedDate(toMariaDBTimestamp(newSelected));
    }
  }

  return (
    <>
      {/* <div className="collapse collapse-arrow bg-base-200 rounded-lg mb-2 border-2 border-t-dark"> */}
      <div className="collapse bg-base-200 rounded-lg mb-2 border-2 border-t-dark">
        <input type="checkbox" defaultChecked disabled style={{ cursor: 'default'}}/>
            <div className="collapse-title text-xl font-medium bg-t-beige">{fieldType}</div>
        <div className="collapse-content">
          <div className="items-center justify-center w-full mt-8">
            <p className="flex mb-2 text-base font-medium text-t-dark">Milyen napokon dolgozol?</p>
            <div className="flex items-center justify-center w-full">
              {selectedOption === 'custom' && (
                <ul className="grid w-full gap-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-7">
                  {Object.entries(selectedDays).map(([dayCode, checked]) => (
                      <li key={dayCode}>
                          <input type="checkbox" id={dayCode} name={dayCode}
                            checked={checked}
                            onChange={handleCheckboxChange} className="hidden peer"/>
                          <label htmlFor={dayCode} className="inline-flex items-center justify-center w-full p-2 text-t-dark bg-t-gray border-2 border-t-ccc rounded-lg cursor-pointer peer-checked:border-t-dark peer-checked:bg-t-blue peer-checked:text-t-dark hover:text-t-light hover:bg-t-dark">                           
                              <div className="block">
                                  {getDayLabel(dayCode)}
                              </div>
                          </label>
                      </li>
                  ))}
                  </ul>
              )}
          </div>
        </div>
        <div className="flex items-center justify-center w-full mt-8">
          {loading ?
            <div>loading...</div>
          :
            <DayPicker
              mode="single"
              required
              ISOWeek
              locale={hu}
              modifiers={modifiers}
              onDayClick={(day) => {
                setIsDateChanged(!isDateChanged);
                setSelectedDate(toMariaDBTimestamp(day)); // Always set the selected date
              }}
              selected={selectedDate}
              onSelect={handleSelect}
              disabled={getDisabledDays()}
            />
          }
          {openWorkPlanModal &&
            <WorkPlanRanges
              userId={userId}
              toast={toast}
              dayName={dayName}
              selectedDate={selectedDate}
              setWorkPlanRanges={setWorkPlanRanges}
              setOriginalWorkPlanRanges={setOriginalWorkPlanRanges}
              originalWorkPlanRanges={originalWorkPlanRanges}
              workPlanRanges={workPlanRanges}
              setOpenWorkPlanModal={setOpenWorkPlanModal}
            />
          }
        </div>
        <div className='text-right'>
            <button className='btn bg-t-green btn-outline mt-4 hover:bg-t-green/75' onClick={updateUserField} disabled={loading || isChanged}>Mentés</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminDateEditor;



// CREATE TABLE userWorks (
//   uuid CHAR(36) PRIMARY KEY NOT NULL DEFAULT (UUID()), -- Auto-generated UUID
//   label VARCHAR(100) NOT NULL,
//   hour INT NOT NULL,
//   minute INT NOT NULL,
//   user_uuid CHAR(36) NOT NULL,
//   FOREIGN KEY (user_uuid) REFERENCES user(uuid) ON DELETE CASCADE
// );


// CREATE TABLE userDates (
//   uuid CHAR(36) PRIMARY KEY NOT NULL DEFAULT (UUID()),
//   date VARCHAR(10) NOT NULL,
//   start_hour INT NOT NULL,
//   start_minute INT NOT NULL,
//   event_uuid CHAR(36) NOT NULL,
//   user_uuid CHAR(36) NOT NULL,
//   FOREIGN KEY (user_uuid) REFERENCES user(uuid) ON DELETE CASCADE
// );