import React, { useEffect, useState } from 'react'
import { DayPicker } from "react-day-picker";
import { hu } from "react-day-picker/locale";
import "react-day-picker/style.css";

const UserCalendar = () => {

    const divClass = "container sm:rounded-lg rounded-none my-4 md:w-4/6 sm:w-5/6 bg-gradient-to-tl from-t-light/20 via-t-light/60 to-t-light/20 px-10 py-10"

    const [selectedDate, setSelectedDate] = useState(toMariaDBTimestamp(new Date()));
  
    const [loading, setLoading] = useState(false);
  
    const modifiers = {};
    if (selectedDate) {
        modifiers.selected = selectedDate;
    }

    const handleSelect = (newSelected) => {
        // if(newSelected) {
        //   setSelectedDate(toMariaDBTimestamp(newSelected));
        //   toast.info(`Ezt a dátumot választottad: ${toMariaDBTimestamp(newSelected)}`)
        // } else {
        //   toast.info(`Ezt a dátumot választottad: ${selectedDate}`)
        // }
    }
    
    function toMariaDBTimestamp(date) {
        const year = date?.getFullYear();
        const month = String(date?.getMonth() + 1).padStart(2, '0'); // A hónapokat nulláról indexelik
        const day = String(date?.getDate()).padStart(2, '0');
        const hours = String(date?.getHours()).padStart(2, '0');
        const minutes = String(date?.getMinutes()).padStart(2, '0');
        const seconds = String(date?.getSeconds()).padStart(2, '0');
        
        const daysOfWeek = ["Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"];
        const dayOfWeek = daysOfWeek[date?.getDay()];
        
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}, ${dayOfWeek}`;
    }
    
    useEffect(() => {
    // Function to toggle the div visibility based on rdp-selected class
        const handleClick = (event) => {
            const tdElement = event.currentTarget;
            const divElement = tdElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;

            // Remove existing 'rdp-selected' class from all <td> elements
            document.querySelectorAll('td').forEach(td => td.classList.remove('rdp-selected'));

            // Add the 'rdp-selected' class to the clicked <td>
            tdElement.classList.add('rdp-selected');

            // Remove existing new-div from the DOM
            const existingDiv = document.querySelector('.new-div');
            if (existingDiv) {
                existingDiv.remove();
            }

            // Insert new <div> after the button in the clicked <td>
            const buttonElement = tdElement.querySelector('button');
            if (buttonElement) {
                const newDiv = document.createElement('div');
                newDiv.textContent = 'New Div Content'; // Customize content
                newDiv.className = 'new-div'; // Assign a class for easy targeting
                buttonElement.insertAdjacentElement('afterend', newDiv);
            }
        };

        // Attach event listeners to all <td> elements
        const tdElements = document.querySelectorAll('td');
        tdElements.forEach((td) => {
            td.addEventListener('click', handleClick);
        });

        // Cleanup event listeners on component unmount
        return () => {
            tdElements.forEach((td) => {
            td.removeEventListener('click', handleClick);
            });
        };

    }, [document.querySelector('.rdp-root')]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className={`${divClass} flex justify-center`}>
            <DayPicker
                mode="single"
                required
                ISOWeek
                locale={hu}
                modifiers={modifiers}
                onDayClick={(day, modifiers) => {
                if (modifiers.selected) {
                    setSelectedDate(undefined);
                } else {
                    setSelectedDate(day);
                }
                }}
                disabled={[
                { dayOfWeek: [0, 6] }, // Disable Sundays and Saturdays
                { before: new Date() } // Disable past dates
                ]}
                selected={selectedDate}
                onSelect={handleSelect} />
            </div>
        </>
    )
}

export default UserCalendar