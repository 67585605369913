import React, { useState, useEffect } from 'react';
import EventBlock from './EventBlock';
import EditableEventBlock from './EditableEventBlock';
import NewEventBlock from './NewEventBlock';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import apiClient from '../../helper/apiClient';


export default function AdminEventEditor({ type, userId, identifier }) {

    const [isSaved, setIsSaved] = useState(false);
    const [addEventModalOpen, setAddEventModalOpen] = useState(false);
    const [newEvent, setNewEvent] = useState(null);

    useEffect(() => {
      if (isSaved) {
          toast.success('Sikeres mentés.')
          setIsSaved(false);
      }
  }, [isSaved]);

    const [events, setEvents] = useState([]);
    const [originalEvents, setOriginalEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUserEvents(type);
    }, [type]);

    const getUserEvents = async (type) => {
      setLoading(true);
  
      const uuidFromCookie = Cookies.get('uuid');
  
      const fieldData = {
          type,
          userId: uuidFromCookie,
          identifier
      };
  
      try {
        const response = await apiClient.post(`/api/events/select`, fieldData);
  
        if (response.status === 404) {
          // handleLogout();
          setLoading(false);
          return;
        }

        const data = response.data;
        if (data) {
          const orderedEvents = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          setEvents(orderedEvents);
          setOriginalEvents(orderedEvents);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };


    const handleLogout = () => {
        Cookies.remove('uuid');
        Cookies.remove('firstname');
        window.location.reload();
    };

    const getEventIds = (events) => events.map((event) => event.id);

    const updateUserEvents = async (events) => {
      try {
          const eventIds = getEventIds(events);
          const originalEventIds = getEventIds(originalEvents);
          const deletableIds = originalEventIds.filter(id => !eventIds.includes(id));
          const response = await apiClient.post(`/api/events/save`, { events, userId, deletableIds });
  
          if (response.status === 200) {
            setOriginalEvents(events);
            setIsSaved(true);
          }
      } catch (error) {
          console.log('error', error)
          if (error.code === 'ECONNABORTED') {
              toast.error('A szerver nem válaszolt időben.');
          } else if (error.response && error.response.data && error.response.data.code === 'ER_DUP_ENTRY') {
              toast.error('A szolgáltatás már létezik.');
          } else {
              console.error('Error:', error);
              toast.error('Hiba történt.');
          }
      }
  };


  const addEvent = () => {
    const event = { id: uuidv4(), label: '', active: false,  description: '', price: 0,  headcount: 0, image:'', hour: 0, minute: 0, date_type: '', recurring_days: null, start_hour: 0, start_minute: 0, event_dates: [] }
    setNewEvent(event);
    const newEvents = [event, ...events];
    setEvents(newEvents);
    setAddEventModalOpen(true)
    // const newEvents = [...events, { id: nanoid(), label: '', description: '',  headcount: 0, image:'', hour: 0, minute: 0, date_type: '', recurring_days: null, start_hour: 0, start_minute: 0 }];
    updateUserEvents(newEvents);
  };

  // const addNewEvent = (newEvent) => {
  //   const newEvents = [newEvent, ...events];
  //   setEvents(newEvents);
  //   updateUserEvents(newEvents);
  // };

  // const removeEvent = (id) => {
  //   const newEvents = events.filter(event => event.id !== id);
  //   setEvents(newEvents);
  //   updateUserEvents(newEvents)
  //   //TO DO: remove event's image
  // };

  const updateEvent = (id, updatedEvent) => {
    const newEvents = events.map(event => event.id === id ? updatedEvent : event);
    setEvents(newEvents);
    updateUserEvents(newEvents)
  };
  

  if(loading) {
    return (
      <div className='flex justify-center min-h-50-screen'>
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    ) 

  }

  return (
    <>
    <div className="flex justify-between items-center text-3xl mb-12 font-medium leading-none tracking-tight text-t-dark">
      <div>Szolgáltatásaim</div>
      <button
        onClick={addEvent}
        className='btn bg-t-green btn-outline hover:bg-t-green/75 font-medium text-3xl'
      >
        +
      </button>
    </div>
    <div className='grid sm:grid-cols-2 xl:grid-cols-3 gap-4'>
      {/* <div
        onClick={addEvent}
        // onClick={() => setAddEventModalOpen(true)}
        className='card cursor-pointer hover:bg-t-light card-compact bg-t-light/50 shadow-xl text-center justify-center text-7xl font-normal'>
          +
      </div> */}
      {events && events.map(event => (
        <EventBlock
          key={event.id}
          event={event}
        />
        // <EditableEventBlock
        //   key={event?.id}
        //   toast={toast}
        //   userId={userId}
        //   identifier={identifier}
        //   event={event}
        //   removeEvent={removeEvent}
        //   updateEvent={updateEvent}
        // />
      ))}

      {addEventModalOpen &&
        <NewEventBlock
          toast={toast}
          event={newEvent}
          userId={userId}
          identifier={identifier}
          setAddEventModalOpen={setAddEventModalOpen}
          updateEvent={updateEvent}
        />
      }
      {/* <div
        onClick={addEvent} 
        className='card cursor-pointer hover:bg-t-light card-compact bg-t-light/50 shadow-xl text-center justify-center text-7xl font-normal'>
          +
      </div> */}
        
    </div>
    </>
  );
}