import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../helper/apiClient';

const UserMenu = ({ template, identifier }) => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const getFields = async () => {
        try {
            const response = await apiClient.get(`/api/fields`, {
                params: { identifier }, // Use params to construct the query string
            });

            // Set the fields data from the response
            setEmail(response?.data?.email);
            setPhone(response?.data?.phone);

        } catch (error) {
            // Handle error response specifically
            if (error.response) {
                console.error('Error fetching user fields:', error.response);
                // Optionally handle specific status codes if needed
            } else {
                console.error('Error fetching user fields:', error);
            }
        }
    };
            
        getFields()
        setLoading(false);

    }, [identifier]);


    const handleNavigate = (path) => {
        navigate(path, { replace: true });
        setIsMenuOpen(false); // Close the menu after navigation
    };

    // Close menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="navbar shadow" style={{ background: template?.primary_color, color: template?.dark_color }}>
                <div className="navbar-start text-sm flex items-start flex-col gap-1 ml-2 sm:ml-4">
                    {email && (
                        <div className="flex items-center">
                            <svg
                                className="mt-1 mr-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke={template?.dark_color}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                <polyline points="22,6 12,13 2,6"></polyline>
                            </svg>
                            {email}
                        </div>
                    )}
                    {phone && (
                        <div className="flex items-center">
                            <svg
                                className="mt-1 mr-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke={template?.dark_color}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                            </svg>
                            {phone}
                        </div>
                    )}
                </div>
                <div className="navbar-center"></div>
                <div className="navbar-end">
                    <div className="dropdown dropdown-end md:hidden">
                        <div
                            tabIndex={0}
                            role="button"
                            className={`btn btn-ghost btn-circle hover:bg-[--hover-color]`}
                            style={{ '--hover-color': template?.background_color }}
                            ref={buttonRef}
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke={template?.dark_color}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line x1="3" y1="12" x2="21" y2="12"></line>
                                <line x1="3" y1="6" x2="21" y2="6"></line>
                                <line x1="3" y1="18" x2="21" y2="18"></line>
                            </svg>
                        </div>
                        {isMenuOpen && (
                            <ul
                                ref={menuRef}
                                tabIndex={0}
                                className="menu menu-lg dropdown-content z-[2] w-60 p-2 shadow-md rounded-bl-box"
                                style={{
                                    background: template?.primary_color,
                                    color: template?.dark_color,
                                    top: '56px',
                                    right: '-8px',
                                }}
                            >
                                <li>
                                    <a
                                        className={`hover:bg-[--hover-color]`}
                                        style={{ '--hover-color': template?.background_color }}
                                        onClick={() => handleNavigate(`/${identifier}`)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke={template?.dark_color}
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M20 9v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9" />
                                            <path d="M9 22V12h6v10M2 10.6L12 2l10 8.6" />
                                        </svg>
                                        Bemutatkozás
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={`hover:bg-[--hover-color]`}
                                        style={{ '--hover-color': template?.background_color }}
                                        onClick={() => handleNavigate(`/${identifier}/events`)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke={template?.dark_color}
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <line x1="8" y1="6" x2="21" y2="6"></line>
                                            <line x1="8" y1="12" x2="21" y2="12"></line>
                                            <line x1="8" y1="18" x2="21" y2="18"></line>
                                            <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                            <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                            <line x1="3" y1="18" x2="3.01" y2="18"></line>
                                        </svg>
                                        Szolgáltatásaim
                                    </a>
                                </li>
                            </ul>
                        )}
                    </div>
                    <div className="hidden md:flex">
                        <ul className="menu menu-horizontal px-1 text-base">
                            <li>
                                <a
                                    className={`hover:bg-[--hover-color]`}
                                    style={{ '--hover-color': template?.background_color }}
                                    onClick={() => handleNavigate(`/${identifier}`)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={template?.dark_color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 9v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9"/><path d="M9 22V12h6v10M2 10.6L12 2l10 8.6"/></svg>
                                    Bemutatkozás
                                </a>
                            </li>
                            <li>
                                <a
                                    className={`hover:bg-[--hover-color]`}
                                    style={{ '--hover-color': template?.background_color }}
                                    onClick={() => handleNavigate(`/${identifier}/events`)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={template?.dark_color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                                    Szolgáltatásaim
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserMenu;
