import React, {useEffect, useState} from 'react';
import '../App.css';
import { useParams } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import apiClient from '../helper/apiClient';
import UserHomepage from './UserHomepage';
import UserCalendar from './UserCalendar';
import UserEvents from './UserEvents';
import UserMenu from '../components/UserMenu';
import Footer from '../components/Footer';

function UserPage({ page }) {

  const { identifier } = useParams();
  const [template, setTemplate] = useState(null);

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const getFields = async () => {
    try {
        const response = await apiClient.get(`/api/fields`, {
            params: { identifier }, // Use params to construct the query string
        });

        // Set the fields data from the response
        setEmail(response?.data?.email);
        setPhone(response?.data?.phone);

    } catch (error) {
        // Handle error response specifically
        if (error.response) {
            console.error('Error fetching user fields:', error.response);
            // Optionally handle specific status codes if needed
        } else {
            console.error('Error fetching user fields:', error);
        }
    }
  };
          
    const getTemplate = async () => {
      try {
          const response = await apiClient.get(`/api/template`, {
            params: { identifier }, // Use params to construct the query string
          });
  
          const data = response.data;
          setTemplate(data);
  
      } catch (error) {
          console.error('Error fetching user template:', error);
      }
  };
      
    getTemplate()
    getFields()
    setLoading(false);

  }, [identifier]);

  
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className='user-page pb-40'
        style={{ background: template?.background_color, color: template?.dark_color}}
      >
        <UserMenu template={template} identifier={identifier} phone={phone} email={email} />
        {page === 'main' && <>
          <UserHomepage template={template} identifier={identifier} />
        </>}

        {page === 'calendar' && <>
          <UserCalendar identifier={identifier} />
        </>}

        {page === 'events' && <>
          <UserEvents template={template} identifier={identifier} />
        </>}

      </div>
      <Footer template={template} />
    </>
  )
}

export default UserPage;
