import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie'; // Import js-cookie to handle cookies
import '../App.css';
import { useParams } from 'react-router-dom';
import AdminPage from './AdminPage'; // Import your AdminPage component
import AdminLogin from './AdminLogin'; // Import your AdminLogin component
import Footer from '../components/Footer';
// import { useLocation } from 'react-router-dom';
// import AdminForgotPassword from './AdminForgotPassword';
import { useNavigate } from 'react-router-dom';

function Admin() {
  const [user, setUser] = useState(null); // State to store user info
  const [userFirstName, setUserFirstName] = useState(null);
  const { identifier } = useParams();
  const [loading, setLoading] = useState(true);
  // const [page, setPage] = useState("login");

  // const adminEditorPages = ['account', 'calendar', 'introduction', 'events', 'workplan'];
  // const adminPages = ['login', 'forgot'];
  const navigate = useNavigate();

  // const { search } = useLocation();

  // useEffect(() => {
      // const params = new URLSearchParams(search);
      // const urlPage = params.get('p') || 'login';
      
      // setPage(urlPage);

  // }, [search]);
  
  useEffect(() => {
    // Get the uuid from the cookie
    const uuid = Cookies.get('uuid');
    const firstname = Cookies.get('firstname');

    // const params = new URLSearchParams(search);
    // const urlPage = params.get('p');
    
    if (uuid) {
      setUser({ uuid });
      setUserFirstName(firstname);

      
      // if(urlPage && !urlPage.includes(adminEditorPages)) {
      //   navigate(`/${identifier}/admin?p=account`, { replace: true });
      // }

    } else {
      setUser(null);

      // if(urlPage && urlPage.includes(adminPages)) {
      //   navigate(`/${identifier}/admin?p=${urlPage}`, { replace: true });
      // }

      navigate(`/${identifier}/admin`, { replace: true });

    }
    setLoading(false);
  }, []);

  if (loading) {
    return (
        <div className='flex justify-center min-h-50-screen'>
            <span className="loading loading-spinner loading-lg"></span>
        </div>
    );
}
  
  return (
    <>
      {user && (
      <>
        <AdminPage userId={user?.uuid} userFirstName={userFirstName} identifier={identifier} />
        <Footer />
      </>
      )}
      
      {/* {(!user && page === "forgot") &&
      <>
        <AdminForgotPassword identifier={identifier} />
        <Footer />
      </>
      } */}

      {!user &&
      // {(!user && page === "login") &&
      <>
        <AdminLogin identifier={identifier} />
        <Footer />
      </>
      }
    </>
  );
}

export default Admin;
