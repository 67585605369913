import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
// import NotFound from './components/NotFound';
import UserPage from './pages/UserPage';
// import UserCalendar from './pages/UserCalendar';
import Admin from './pages/Admin';
import Register from './pages/Register';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from './components/Logo';
import SuccessRegister from './pages/SuccessRegister';
import AdminForgotPassword from './pages/AdminForgotPassword';
import AdminNewPassword from './pages/AdminNewPassword';
import UserEventPage from './pages/UserEventPage';


function App() {
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        closeButton={false}
        transition={Bounce}
      />
      <Logo/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="/forgot" element={<AdminForgotPassword />} /> */}
        <Route path="/success" element={<SuccessRegister />} />
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="/:identifier" element={<UserPage page={'main'} />} />
        <Route path="/:identifier/forgotpassword" element={<AdminForgotPassword />} />
        
        <Route path="/:identifier/newpass/:forgotpasswordid" element={<AdminNewPassword />} />

        {/* <Route path="/:identifier/calendar" element={<UserPage page={'calendar'} />} /> */}
        <Route path="/:identifier/event/:eventId" element={<UserEventPage />} />
        <Route path="/:identifier/events" element={<UserPage page={'events'} />} />
        <Route path="/:identifier/admin" element={<Admin />} />
      </Routes>
    </Router>
  );
}

export default App;
