import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiClient from '../../helper/apiClient';

function AdminImageUploader({ userId, identifier }) {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const type = 'image';
  const fieldType = 'Profilkép';
  const [selectedImage, setSelectedImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isSavedImage, setIsSavedImage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [areYouSureToDelete, setAreYouSureToDelete] = useState(false);

  useEffect(() => {
    getUserImage(type);
  }, [type]);
  

  const getUserImage = async (type) => {
    setLoading(true);
  
    const fieldData = {
      type,
      userId,
      identifier
    };
  
    try {
      const response = await apiClient.post(`/api/fields/select`, fieldData);
  
      if (response.status === 404) {
        setLoading(false);
        return;
      }
  
      const data = response.data;
  
      if (data && data.image) {
        setImageUrl(data.image);
        setIsSavedImage(true);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(selectedImage) {
      if(selectedImage.type !== "image/png" && selectedImage.type !== "image/jpeg" && selectedImage.type !== "image/gif") {
        toast.warning('Nem támogatott képformátum.');
        setUploading(false);
        setSelectedImage('');
        return;
      }
  
      if (!(selectedImage.size <= 1000000)) {
        toast.warning('A kép nem lehet nagyobb, mint 1 MB.');
        setUploading(false);
        setSelectedImage('');
        return;
      }
    }
  }, [selectedImage]);

  const handleImageSave = async () => {

    setUploading(true);

    if (!selectedImage) {        
      setUploading(false);
      return;
    }

    if (!selectedImage.type?.startsWith('image/')) {
      toast.error('Kizárólag képet tölthetsz fel.');
      setUploading(false);
      return;
    }

    if(selectedImage.type !== "image/png" && selectedImage.type !== "image/jpeg" && selectedImage.type !== "image/gif") {
      toast.warning('Nem támogatott képformátum.');
      setUploading(false);
      return;
    }

    if (!(selectedImage.size <= 1000000)) {
      toast.warning('A kép nem lehet nagyobb, mint 1 MB.');
      setUploading(false);
      return;
    }

    const fileExtension = selectedImage.name.split('.').pop();
    const newFileName = `${Date.now()}-${identifier}-profile.${fileExtension}`;
    // const newFileName = `${identifier}-profile.${fileExtension}`;
    const renamedImage = new File([selectedImage], newFileName, { type: selectedImage.type });

    const formData = new FormData();
    formData.append('file', renamedImage);
    formData.append('type', type);
    formData.append('userId', userId);
    formData.append('identifier', identifier);

    try {
      const response = await fetch(`${apiBaseUrl}api/profile-image/upload`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
        },
        body: formData,
      });

      if (response.ok) {
        toast.success('Sikeres mentés.');
        setSelectedImage('');
        setIsSavedImage(true);
        setImageUrl('uploads/'+newFileName)
      } else {
        toast.error('Hiba történt.');
      }
    } catch (error) {
      toast.error('Hiba történt.');
    } finally {
      setUploading(false);
    }
  };


  const handleDeleteImage = async () => {
    console.log('imageUrl', imageUrl);
    try {
      const response = await fetch(`${apiBaseUrl}api/profile-image/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
        },
        body: JSON.stringify({ userId, identifier, imageName: imageUrl.replace('uploads/', '')}),
      });

      if (response.ok) {
        setAreYouSureToDelete(false)
        setIsSavedImage(false);
        setImageUrl('');
        setSelectedImage('');
        toast.success('Kép törlése sikeres.');
      } else {
        toast.error('Nem sikerült törölni a képet.');
      }
    } catch (error) {
      toast.error('Hiba történt a törlés során.');
    }
  };

    
  return (
      <>
        <div className="collapse collapse-arrow bg-base-200 rounded-lg mb-2 border-2 border-t-dark">
          <input type="checkbox" defaultChecked/>
          <div className="collapse-title text-xl font-medium bg-t-beige">{fieldType}</div>
          <div className="collapse-content">
            {loading ?
              <div className="flex items-center justify-center w-full mt-8">
                <span className="loading loading-spinner loading-lg"></span>
              </div>
            :
            <>
            <div className="flex items-center justify-center w-full mt-8">
              {isSavedImage ?
                <div className="flex flex-col items-center">
                  <div className={`mb-4 my-0 mx-auto rounded-full w-40 h-40 bg-cover bg-center`} style={{backgroundImage: `url(${apiBaseUrl+imageUrl})`}}/>
                </div>
              :
              selectedImage ? (
                <div className="flex flex-col items-center">
                  <div className={`mb-4 my-0 mx-auto rounded-full w-40 h-40 bg-cover bg-center`} style={{backgroundImage: `url(${URL.createObjectURL(selectedImage)})`}}/>
                </div>
              ) : (
                <label
                  htmlFor="profile-image"
                  className="flex flex-col items-center justify-center w-full h-64 border-2 border-t-gray border-dashed rounded-lg cursor-pointer bg-t-light hover:bg-t-light"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-t-dark"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-t-dark">
                      <span className="font-semibold">Kattints a feltöltéshez</span> vagy húzd ide a fájlt
                    </p>
                    <p className="text-xs text-t-dark">
                      PNG, JPG vagy GIF (MAX. 1MB)
                    </p>
                  </div>
                  <input
                    id="profile-image"
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) setSelectedImage(file);
                    }}
                  />
                </label>
              )}
              </div>
              <div className="text-right">
                {areYouSureToDelete ? (
                  <>
                    <button
                      className="btn bg-t-green btn-outline mt-4 mr-2 hover:bg-t-green/75"
                      onClick={() => setAreYouSureToDelete(false)}
                      disabled={uploading}
                    >
                      Mégse
                    </button>
                    <button
                      className="btn bg-t-pink btn-outline mt-4 hover:bg-t-pink/75"
                      onClick={handleDeleteImage}
                      disabled={uploading}
                    >
                      Végleges törlés
                    </button>
                  </>
                ) : isSavedImage && (
                  <button
                    className="btn bg-t-pink btn-outline mt-4 hover:bg-t-pink/75"
                    onClick={() => setAreYouSureToDelete(true)}
                    disabled={uploading}
                  >
                    Törlés
                  </button>
                )}

                {selectedImage &&
                  <button
                    className="btn bg-t-pink btn-outline mt-4 mr-2 hover:bg-t-pink/75"
                    onClick={() => setSelectedImage('')}
                    disabled={uploading}
                  >
                    Mégse
                  </button>
                }
                {selectedImage &&
                  <button
                    className="btn bg-t-green btn-outline mt-4 hover:bg-t-green/75"
                    onClick={() => handleImageSave()}
                    disabled={uploading}
                  >
                    Mentés
                  </button>
                }
              </div>
              </>
              }
          </div>
        </div>
      </>
    );
}

export default AdminImageUploader;
