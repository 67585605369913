import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // To manage cookies
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiClient from '../../helper/apiClient';

function AdminPasswordEditor({ userId, identifier }) {

  const [currentPassword, setCurrentPassword] = useState('');
  
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState(
      {
          length: false,
          uppercase: false,
          number: false,
          specialChar: false
      }
  );

  const [inputErrors, setInputErrors] = useState(
      {
          currentPassword: false
      }
  );

  const [loading, setLoading] = useState(false);

  const labelClass = "focus:outline-none font-medium p-2 border bg-t-light w-full flex items-center gap-2"

  const isError = async () => {
    let hasError = false;

    if(!currentPassword) {
        if(!hasError) {
            toast.error('Meg kell adnod a jelenlegi jelszavadat.');
        }
        setInputErrors(prevState => ({
            ...prevState,
            currentPassword: true,
        }));
        hasError = true;
    }

    if(!isPasswordMatch) {
      if(!hasError) {
          toast.error('A két jelszónak egyeznie kell.');
      }
      hasError = true;
  }

  if(!passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) {
      if(!hasError) {
          toast.error('A jelszónak biztonságosnak kell lennie.');
      }
      hasError = true;
  }

    return hasError;
};

  const handleLogout = () => {
    Cookies.remove('uuid');
    Cookies.remove('firstname');
    window.location.reload();
  };


  
  const updateNewPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
        if (await isError()) {
            // scrollToTop();
            setLoading(false);
            return;
        }

        const userData = { newPassword, identifier, userId };
        await apiClient.post(`/api/admin/new-password`, userData);

        toast.success('Sikeresen megváltoztattad a jelszavadat.');
        setNewPassword('');
        setNewPasswordConfirmation('');

    } catch (error) {
        handleNewPasswordError(error);
    } finally {
        setLoading(false);
    }
};

const handleNewPasswordError = (error) => {
    if (error.response?.status === 401) {
        toast.error('Hibás azonosító vagy jelszó.');
    } else if (error.code === 'ECONNABORTED') {
        toast.error('A szerver nem válaszolt időben.');
    } else {
        toast.error('Ismeretlen hiba történt.');
    }
};



const scrollToTop = () => {
    if(window !== undefined) {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        });
    }
};

const handlePasswordChange = (value, isConfirmation = false) => {
    if (!isConfirmation) {
        setNewPassword(value);
    } else {
        setNewPasswordConfirmation(value);
    }
    setIsPasswordMatch(!isConfirmation ? value === newPasswordConfirmation : newPassword === value);
    checkPasswordCriterias(value);
};

const checkPasswordCriterias = (value) => {
    const criterias = {
        length: value.length >= 9,
        uppercase: /[A-Z]/.test(value),
        number: /\d/.test(value),
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value)
    }
    setPasswordCriteria(criterias)
};



  if (loading) {
    return (
        <div className='flex justify-center min-h-50-screen'>
            <span className="loading loading-spinner loading-lg"></span>
        </div>
    );
}

  return (
    <>
      <div className="collapse bg-base-200 rounded-lg mb-2 border-2 border-t-dark">
        <input type="checkbox" defaultChecked disabled style={{ cursor: 'default'}}/>
        <div className="collapse-title text-xl font-medium bg-t-beige">Új jelszó</div>
        <div className="collapse-content">
          <label className={`${labelClass} mt-8`}>
            Új jelszó
            <input type="password" className="grow focus:outline-none font-normal"
              placeholder="********"
              value={newPassword} 
              onFocus={(e) => {
                  handlePasswordChange(e.target.value);
              }}
              onBlur={(e) => {
                  handlePasswordChange(e.target.value);
              }}
              onChange={(e) => {
                  handlePasswordChange(e.target.value);
              }}
            />
          </label>
          <label className={`${labelClass} mt-4`}>
            Új jelszó megerősítése
            <input type="password" className="grow focus:outline-none font-normal"
              placeholder="********"
              value={newPasswordConfirmation}
              onFocus={(e) => {
                  handlePasswordChange(e.target.value, true);
              }}
              onBlur={(e) => {
                  handlePasswordChange(e.target.value, true);
              }}
              onChange={(e) => {
                  handlePasswordChange(e.target.value, true);
              }}
            />
          </label>
          {!loading && <>
              <div className="criterias text-left mx-6">
                  <div className="criterias-title">Az jelszavadnak tartalmaznia kell:</div>
                  <ul>
                      {<li className={!passwordCriteria.length ? `list-disc` : `list-image-checkmark`}>legalább 9 karaktert</li>}
                      {<li className={!passwordCriteria.uppercase ? `list-disc` : `list-image-checkmark`}>kis- és nagybetűt</li>}
                      {<li className={(!passwordCriteria.number || !passwordCriteria.specialChar) ? `list-disc` : `list-image-checkmark`}>minimum egy számot és egy speciális karaktert</li>}
                  </ul>
              </div>
          </>}
          <hr className='mt-12 border-t-dark'/>
          <div className="label">
              <div className='label-text'>Meg kell adnond a jelenlegi jelszavadat.</div>
          </div>
          <label className={`${labelClass} mt-0`}>
            Jelenlegi jelszó
            <input type="password" className="grow focus:outline-none font-normal"
              placeholder="********"
              value={currentPassword}
              onChange={(e) => {
                  setCurrentPassword(e.target.value);
              }}
            />
          </label>
          <div className='text-right'>
            <button className='btn bg-t-green btn-outline mt-4 hover:bg-t-green/75' onClick={updateNewPassword}
              disabled={loading || (!newPassword || !newPasswordConfirmation)}
            >
              Mentés
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminPasswordEditor;