import React, { useState, useEffect } from 'react';

export default function EventBlock({ event }) {
  
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [dateTypeName, setDateTypeName] = useState('');

  useEffect(() => {
    if(event.date_type === 'onetime') {
      setDateTypeName('Egyedi');
    } else if(event.date_type === 'recurring') {
      setDateTypeName('Ismétlődő');
    }
  }, []);


  if(!event) {
    return (
      <div className='flex justify-center min-h-50-screen'>
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    )
  }

  return (
    <>
        <div className="card card-compact bg-base-100 shadow-6xl">
          <figure>
              <div
                className="my-0 mx-auto w-full h-40 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${apiBaseUrl + event?.image})`,
                }}
              />
          </figure>

        <div className="card-body">
          <div className='flex justify-end'>
          {event?.active ?
            <div className='text-base font-bold text-t-dark bg-t-green p-2'>Bekapcsolva</div>
          :
          <div className='text-base font-bold text-t-dark bg-t-pink p-2'>Kikapcsolva</div>
          }
          </div>

          <div className='flex flex-col'>
              <div className='text-xs font-bold'>Esemény neve:</div>
              {event?.label ?
                <div className='text-cut text-xl font-bold'>{event?.label}</div>
              :
                <div className='text-cut text-xl opacity-50'>Még nem adtad meg az esemény nevét</div>
              }
          </div>

          <hr />
          <div className='flex flex-col'>
            <div className='text-xs font-bold'>Leírás:</div>
            {event?.description ?
              <div className='text-cut-description text-base min-h-18' dangerouslySetInnerHTML={{ __html: event?.description }} />
            :
              <div className='opacity-50 text-base min-h-18'>Még nem adtad meg az esemény leírását</div>
            }
          </div>

          <hr />
          <div className='flex flex-col'>
            <div className='text-xs font-bold'>Időtartam:</div>
            {(event?.hour && event?.minute) ?
              <div className='text-base'>{event?.hour} óra {event?.minute} perc</div>
            :
              <div className='opacity-50 text-base'>Még nem adtad meg az esemény időtartamát</div>
            }
          </div>

          <hr />
          <div className='flex flex-col'>
            <div className='text-xs font-bold'>Maximális létszám:</div>
            {event.headcount ?
              <div className='text-base'>{event?.headcount}</div>
            :
              <div className='opacity-50 text-base'>Még nem adtad meg az esemény maximális létszámát</div>
            }
          </div>

          <hr />
          <div className='flex flex-col'>
            <div className='text-xs font-bold'>Ár:</div>
            {event.price ?
              <div className='text-base'>{event?.price} HUF</div>
            :
              <div className='opacity-50 text-base'>Még nem adtad meg az esemény árát</div>
            }
          </div>

          <hr />
          <div className='flex flex-col'>
            <div className='text-xs font-bold'>Esemény típusa:</div>
            {(event.date_type && dateTypeName) ?
              <div className='text-base'>{dateTypeName}</div>
            :
              <div className='opacity-50 text-base'>Még nem választottál típust</div>
            }
          </div>

          <hr />
          <div className="mt-8">
            <button
              className="w-full btn bg-t-green btn-outline mt-4 mr-2 hover:bg-t-green/75"
              onClick={() => console.log('EDIT')}
            >
              Szerkesztés
            </button>
          </div>
        </div>
        </div>
</>
  );
}
