import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import apiClient from '../../helper/apiClient';

const TemplatesBlock = ({ userId, identifier }) => {

  const [templates, setTemplates] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState('');
  const [originalCurrentTemplate, setOriginalCurrentTemplate] = useState('');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = async () => {
    setLoading(true);

    try {
      const response = await apiClient.post(`/api/templates`, {userId, identifier});

      const data = response.data;

      if (data) {
        setTemplates(data?.templates);
        setCurrentTemplate(data?.userTemplate);
        setOriginalCurrentTemplate(data?.userTemplate);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

    const updateSelectedTemplate = async () => {
        setLoading(true);

        const fieldData = {
            userId,
            identifier,
            templateId: currentTemplate
        };

        try {
            const response = await apiClient.put(`/api/template/update`, fieldData);

            if (response.status === 500) {
                // handleLogout();
                return;
            }

            toast.success('Sikeres mentés.');
            setOriginalCurrentTemplate(currentTemplate);
        } catch (error) {
            console.error('Error updating field:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="collapse collapse-arrow bg-base-200 rounded-lg mb-2 border-2 border-t-dark">
            <input type="checkbox" defaultChecked/>
            <div className="collapse-title text-xl font-medium bg-t-beige">Kinézet</div>
        <div className="collapse-content">
            {/* <div className='flex flex-col mt-8'> */}
            <div className='grid sm:grid-cols-2 xl:grid-cols-3 gap-2 mt-8'>
                {templates && templates.map(template => (
                    <label key={template.uuid} className="radio-label p-2 flex items-center">
                        <input 
                        type="radio" 
                        name="radio-1" 
                        className="radio mr-2" 
                        value={template.uuid} 
                        defaultChecked={template?.uuid === currentTemplate}
                        onClick={() => setCurrentTemplate(template.uuid)}
                        />
                        <div className='border flex items-center border-t-dark'>
                            <div className="w-6 h-6" style={{backgroundColor: `${template?.primary_color}`}}/>
                            <div className="w-6 h-6" style={{backgroundColor: `${template?.secondary_color}`}}/>
                            <div className="w-6 h-6" style={{backgroundColor: `${template?.background_color}`}}/>
                            <div className="w-6 h-6" style={{backgroundColor: `${template?.dark_color}`}}/>
                            <div className="w-6 h-6" style={{backgroundColor: `${template?.light_color}`}}/>
                        </div>
                        <div className='ml-2'>{template?.name}</div>
                    </label>
                ))}
            </div>
            <div className='text-right'>
                <button className='btn bg-t-green btn-outline mt-4 hover:bg-t-green/75' onClick={updateSelectedTemplate} disabled={loading || (currentTemplate === originalCurrentTemplate)}>Mentés</button>
            </div>
            </div>
        </div>
    )
}

export default TemplatesBlock