import React, { useEffect, useState } from 'react';
import apiClient from '../helper/apiClient';
import UserEventMiniBlock from '../components/UserEventMiniBlock';

const UserEvents = ({ identifier, template }) => {
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectedOption, setSelectedOption] = useState("all-s");

    const handleOptionChange = (value) => {
        setSelectedOption(value);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    useEffect(() => {
        const getEvents = async () => {
            try {
                const response = await apiClient.get(`/api/events`, {
                    params: { identifier }, // Use params to construct the query string
                });
                setEvents(response.data);
                setFilteredEvents(response.data); // Initialize filteredEvents
            } catch (error) {
                if (error.response) {
                    console.error('Error fetching user fields:', error.response);
                } else {
                    console.error('Error fetching user fields:', error);
                }
            } finally {
                setLoading(false);
            }
        };
        getEvents();
    }, [identifier]);

    useEffect(() => {
        // Apply filters based on searchText and selectedOption
        let filtered = events;

        if (searchText) {
            filtered = filtered.filter((event) =>
                event.label.toLowerCase().includes(searchText.toLowerCase())
            );
        }

        if (selectedOption && selectedOption !== "all-s") {
            filtered = filtered.filter((event) =>
                selectedOption === "individual-s"
                    ? event.headcount === 1
                    : event.headcount > 1
            );
        }

        setFilteredEvents(filtered);
    }, [searchText, selectedOption, events]);

    if (loading) {
        return (
            <div className='flex justify-center min-h-50-screen'>
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        );
    }

    return (
        <>
        <div style={{"--light-color": template?.light_color, "--dark-color": template?.dark_color}}
            className="
                container mt-0 xs:mt-2 mb-2 p-3
                bg-[--light-color]
                border-0 xs:border-2
                border-[--dark-color] rounded-none xs:rounded-lg shadow-6xl
            "
        >
            <div className='w-full grid gap-2 grid-cols-1 sm:grid-cols-2 md:px-2 px-1 py-1 place-items-center'>
                {/* <div className="flex w-full sm:w-1/2 items-center justify-center bg-t-light rounded-lg"> */}
                    <label className="w-full md:max-w-80 rounded-lg focus:outline-none font-medium px-4 py-2 border border-t-ccc bg-t-light flex items-center gap-2">
                        <input
                            type="text"
                            className="grow focus:outline-none font-normal"
                            placeholder="Keresés"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                        <div className='w-6 h-6 cursor-pointer flex items-center justify-center'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className="h-4 w-4 opacity-70">
                                <path
                                    fillRule="evenodd"
                                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                                    clipRule="evenodd" />
                            </svg>
                        </div>
                    </label>
                {/* </div> */}

                {/* <div className="flex items-center justify-center md:pt-4 pt-2 mb-2"> */}
                    <ul className="grid w-full gap-2 grid-cols-3 md:w-3/4">
                        {[
                            { id: "all-s", label: "Összes", value: "all-s" },
                            { id: "individual-s", label: "Egyéni", value: "individual-s" },
                            { id: "group-s", label: "Csoportos", value: "group-s" },
                        ].map((option) => (
                            <li key={option.id}>
                                <input
                                    type="radio"
                                    id={option.id}
                                    name="event-types"
                                    value={option.value}
                                    className="hidden peer"
                                    required
                                    checked={selectedOption === option.value}
                                    onChange={() => handleOptionChange(option.value)}
                                />
                                <label
                                    htmlFor={option.id}
                                    style={{
                                        "--primary-color": template?.primary_color,
                                        "--secondary-color": template?.secondary_color,
                                        "--bgd-color": template?.background_color,
                                        "--light-color": template?.light_color,
                                        "--dark-color": template?.dark_color,
                                    }}
                                    className="inline-flex items-center justify-center w-full
                                                p-2
                                                border
                                                border-[--light-color]
                                                rounded-lg cursor-pointer
                                                text-[--dark-color]
                                                bg-[--secondary-color]
                                                peer-checked:border-[--dark-color]
                                                peer-checked:text-[--dark-color]
                                                peer-checked:bg-[--primary-color]
                                                hover:bg-[--primary-color]
                                                hover:border-[--primary-color]"
                                >
                                    <div className="block">
                                        <div className="w-full text-sm font-semibold">{option.label}</div>
                                    </div>
                                </label>
                            </li>
                        ))}
                    </ul>
                {/* </div> */}
            </div>
        </div>

            <div className='grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 l:grid-cols-4 2xl:grid-cols-5 gap-2 md:gap-4 md:p-4 p-2 w-full'>
                {filteredEvents.map((event, index) => (
                    <UserEventMiniBlock
                        key={index}
                        identifier={identifier}
                        event={event}
                        template={template}
                    />
                ))}
            </div>
        </>
    );
};

export default UserEvents;
