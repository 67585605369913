import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { quillCustomFormats, quillCustomModules } from '../../helper/quillOptions';
import { v4 as uuidv4 } from 'uuid';
import EventDatesBlock from './EventDatesBlock';


  export default function NewEventBlock({ toast, event, identifier, userId, setAddEventModalOpen, updateEvent }) {
      
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [isOpen, setIsOpen] = useState(true);

    const [eventDates, setEventDates] = useState([]);

    const addEventDate = () => {
        setEventDates([...eventDates, { uuid: uuidv4(), date: null,
        start_hour: null, start_minute: null, event_id: event?.id }]);
    };

    const removeEventDate = (uuid) => {
        setEventDates((prevEventDates) =>
            prevEventDates.filter((eventDate) => eventDate.uuid !== uuid)
        );
    };
    

    const updateEventDate = (uuid, newEventDate) => {
        setEventDates(eventDates?.map(eventDate => eventDate.uuid === uuid ? newEventDate : eventDate));
    };
    
    const [description, setDescription] = useState(event?.description);

  useEffect(() => {
    if (event) {
      const defaultDays = {
        H: false,
        K: false,
        Sze: false,
        Cs: false,
        P: false,
        Szo: false,
        V: false,
      };
      
      let parsedDays;
      try {
        parsedDays = event?.recurring_days ? JSON.parse(event.recurring_days) : defaultDays;

      } catch (error) {
        console.error("Error parsing recurring_days:", error);
        parsedDays = defaultDays;
      }
      setRecurringDays(parsedDays)
      setLoading(false);
    }
  }, []);

  const [active, setActive] = useState(event?.active || false);
  const [label, setLabel] = useState(event?.label || '');

  const [price, setPrice] = useState(event?.price || '');

  const [hour, setHour] = useState(event?.hour);
  const [minute, setMinute] = useState(event?.minute);

  const [dateType, setDateType] = useState(event?.date_type);

  const [recurringDays, setRecurringDays] = useState({
    H: false,
    K: false,
    Sze: false,
    Cs: false,
    P: false,
    Szo: false,
    V: false,
  });

  const [headcount, setHeadcount] = useState(event?.headcount);

  const [startHour, setStartHour] = useState(
    event?.start_hour !== undefined
      ? event?.start_hour?.toString().padStart(2, '0')
      : '00'
  );
  
  const [startMinute, setStartMinute] = useState(
    event?.start_minute !== undefined
      ? event?.start_minute?.toString().padStart(2, '0')
      : '00'
  );  

  const [selectedImage, setSelectedImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isSavedImage, setIsSavedImage] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);

  const [imageDeleteConfirm, setImageDeleteConfirm] = useState(false);
//   const [eventDeleteConfirm, setEventDeleteConfirm] = useState(false);

//   const [selectedRecurringDate, setSelectedRecurringDate] = useState(event?.date);

//   const handleDateChange = (event) => {
//     setSelectedDate(event.target.value);
//   };

  const handleSave = () => {
    // updateEvent(event.id, { ...event, active, label, description, headcount, image: imageUrl, hour, minute, date: selectedRecurringDate, date_type: dateType, recurring_days: JSON.stringify(recurringDays), start_hour: startHour, start_minute: startMinute });
    updateEvent(event.id, { ...event, active, label, price, description, headcount, image: imageUrl, hour, minute, date: null, date_type: dateType, recurring_days: JSON.stringify(recurringDays), start_hour: startHour, start_minute: startMinute, event_dates: eventDates });
}

  useEffect(() => {
    if(selectedImage) {
        handleImageSave()
    }
  }, [selectedImage]);

  const handleRecurringDaysChange = (event) => {
    const { name, checked } = event.target;
    setRecurringDays((prevDays) => ({
      ...prevDays,
      [name]: checked,
    }));
  };

  const getRecurringDayLabel = (dayCode) => {
    const days = {
      H: "H",
      K: "K",
      Sze: "Sze",
      Cs: "Cs",
      P: "P",
      Szo: "Szo",
      V: "V",
    };
    return days[dayCode];
  };

  const handleImageSave = async () => {
    setUploading(true);
  
    if (!selectedImage) {
      setUploading(false);
      return;
    }
  
    if (!selectedImage.type?.startsWith('image/')) {
      setSelectedImage('');
      toast.error('Kizárólag képet tölthetsz fel.');
      setUploading(false);
      return;
    }
  
    if (selectedImage.type !== 'image/png' && selectedImage.type !== 'image/jpeg' && selectedImage.type !== 'image/gif') {
      setSelectedImage('');
      toast.warning('Nem támogatott képformátum.');
      setUploading(false);
      return;
    }
  
    if (!(selectedImage.size <= 1000000)) {
      setSelectedImage('');
      toast.warning('A kép nem lehet nagyobb, mint 1 MB.');
      setUploading(false);
      return;
    }
  
    const fileExtension = selectedImage.name.split('.').pop();
    const newFileName = `${identifier}-${Date.now()}-e.${fileExtension}`;
    const renamedImage = new File([selectedImage], newFileName, { type: selectedImage.type });
  
    const formData = new FormData();
    formData.append('file', renamedImage);
    formData.append('userId', userId);
    formData.append('eventId', event?.id);
    formData.append('identifier', identifier);
  
    try {
      const response = await fetch(`${apiBaseUrl}api/event-image/upload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
        body: formData,
      });
  
      if (response.ok) {
        setSelectedImage('');
        setImageUrl('uploads/events/'+newFileName);
        setIsSavedImage(true);
        handleSave();
      } else {
        toast.error('Hiba történt.');
      }
    } catch (error) {
      toast.error('Hiba történt.');
    } finally {
      setUploading(false);
    }
  };
  

  const handleDeleteImage = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}api/event-image/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
        },
        body: JSON.stringify({ eventId: event?.id, userId, identifier, imageName: imageUrl.replace('uploads/', '')}),
      });

      if (response.ok) {
        setImageDeleteConfirm(false);
        setIsSavedImage(false);
        setImageUrl('');
        setSelectedImage('');
        toast.success('Kép törlése sikeres.');
      } else {
        toast.error('Nem sikerült törölni a képet.');
      }
    } catch (error) {
      toast.error('Hiba történt a törlés során.');
    }
  };

  // Helper function to handle input changes
  const handleDigitInput = (e, maxDigits, setter, maxRange) => {
    const value = e.target.value.replace(/\D/, ''); // Remove non-digit characters
    if (value.length <= maxDigits && Number(value) <= maxRange) { // Allow max 2 digits and within range
      // Ensure 0 is correctly interpreted
      setter(value === '' ? '' : Number(value));
    }
  };


  const handleBlurStartHour = () => {
    if (startHour.length === 1) {
      setStartHour(`0${startHour}`);
    }
  };

  const handleBlurStartMinute = () => {
    if (startMinute.length === 1) {
      setStartMinute(`0${startMinute}`);
    }
  };

  const handleStartHourChange = (e) => {
    const value = e.target.value;
    if (/^(2[0-3]|1[0-9]|[0-9])$/.test(value) || value === "") {
      setStartHour(value);
    }
  };

  const handleStartMinuteChange = (e) => {
    const value = e.target.value;
    if (/^[1-5]?[0-9]$/.test(value) || value === "") {
      setStartMinute(value);
    }
  };

  const validateEventDetails = () => {
    if (!imageUrl) {
      toast.error('Kérlek töltsd fel egy képet.');
      return false;
    }
    if (!label) {
      toast.error('Kérlek add meg az esemény nevét.');
      return false;
    }
    if (hour < 0 || minute < 0) {
      toast.error('Kérlek add meg az esemény időtartamát.');
      return false;
    }
    if (!headcount) {
      toast.error('Kérlek add meg az esemény létszámát.');
      return false;
    }
    if (!dateType || dateType === "type") {
      toast.error('Kérlek add meg az esemény típusát.');
      return false;
    }
    // if (dateType === "onetime" && (!selectedRecurringDate || !startHour || !startMinute)) {
    //   toast.error('Kérlek add meg, hogy mikor lesz az esemény.');
    //   return false;
    // }
    if (dateType === "recurring" && !Object.values(recurringDays).some(value => value)) {
      toast.error('Kérlek add meg, hogy mikor lesz az esemény.');
      return false;
    }
    return true;
  };
  

  const handleActive = (checked) => {
    if (!validateEventDetails()) {
      setActive(false);
      return;
    }
    setActive(checked);
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
    setAddEventModalOpen(false);
}

  if(loading) {
    return (
      <div className='flex justify-center min-h-50-screen'>
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    )
  }

  return (
    <>
    <input type="checkbox" id="new_event_modal" className="modal-toggle" defaultChecked={isOpen} />
     <div className="modal" role="dialog">
     <div className="modal-box bg-t-light w-10/12 max-w-5xl p-0">
        <div className="card card-compact bg-base-100 shadow-6xl">
          <figure>
            {isSavedImage || selectedImage ? (
              <div
                className="my-0 mx-auto w-full h-40 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${isSavedImage ? apiBaseUrl + imageUrl : URL.createObjectURL(selectedImage)})`,
                }}
              >
                <div className='flex justify-end items-start h-40 p-2'>
                  {imageDeleteConfirm ? (
                    <>
                      <button
                        className="btn bg-t-green btn-outline hover:bg-t-green min-h-8 h-8 px-1 mr-2"
                        onClick={() => setImageDeleteConfirm(false)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 17l-5-5 5-5M18 17l-5-5 5-5"/></svg>
                      </button>
                      
                      <button
                        className="btn bg-t-pink btn-outline hover:bg-t-pink min-h-8 h-8 px-1"
                        onClick={handleDeleteImage}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                      </button>
                    </>
                  ) : isSavedImage && (
                    <button
                      className="btn bg-t-pink btn-outline hover:bg-t-pink min-h-8 h-8 px-1"
                      onClick={() => setImageDeleteConfirm(true)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <label
                htmlFor={`new-event-image-${event?.id}`}
                className="flex flex-col items-center justify-center w-full h-40 border-2 border-t-gray border-dashed rounded-2xl rounded-b-none cursor-pointer bg-t-light hover:bg-t-light"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-t-dark"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-t-dark">
                    <span className="font-semibold">Kattints a feltöltéshez</span> vagy húzd ide a fájlt
                  </p>
                  <p className="text-xs text-t-dark">PNG, JPG vagy GIF (MAX. 1MB)</p>
                </div>
                <input
                  id={`new-event-image-${event?.id}`}
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) setSelectedImage(file);
                  }}
                />
              </label>
            )}
          </figure>

        <div className="card-body">
          <div className='flex justify-end'>
              <label className="cursor-pointer label justify-start w-fit">
                <input type="checkbox" 
                  checked={active}
                  onChange={(e) => handleActive(e.target.checked)}
                  // onChange={(e) => setActive(e.target.checked)}
                  className="toggle mr-2" />
                <span className="label-text text-t-dark text-lg">Bekapcsolás</span>
              </label>
            </div>

            <div className='flex'>
                <input
                    className='text-xl w-full focus:outline-none font-medium px-4 py-3 border border-t-ccc bg-t-light flex items-center gap-2'
                    placeholder="Szolgáltatásod neve"
                    value={label}
                    maxLength={24}
                    onChange={(e) => setLabel(e.target.value)}
                />
            </div>

            <hr />
            <div>Leírás</div>
            <ReactQuill 
              className='bg-t-light'
              modules={quillCustomModules}
              formats={quillCustomFormats}
              theme="snow"
              value={description}
              onChange={setDescription}
            />

            <hr className='mt-2'/>
            <div>Ár</div>
            <input
              type='text'
              className='w-full focus:outline-none font-medium px-4 py-3 border border-t-ccc bg-t-light flex items-center gap-2'
              placeholder="0"
              value={price}
              onChange={(e) => handleDigitInput(e, 10, setPrice, 1000000)}
            />

            <hr />
            <div>Időtartam</div>

            <div className="join w-full justify-center rounded-none">
              <select
                  className="select w-1/2 focus:outline-none rounded-none join-item border border-t-ccc bg-t-light"
                  value={hour}
                  onChange={(e) => setHour(Number(e.target.value))}
              >
                  <option disabled value={10}>Időtartam</option>
                  <option value={0}>0 óra</option>
                  <option value={1}>1 óra</option>
                  <option value={2}>2 óra</option>
                  <option value={3}>3 óra</option>
                  <option value={4}>4 óra</option>
              </select>
              <select
                  className="select w-1/2 focus:outline-none rounded-none join-item border border-t-ccc bg-t-light"
                  value={minute}
                  onChange={(e) => setMinute(Number(e.target.value))}
              >
                  <option disabled value={10}>Időtartam</option>
                  <option value={0}>0 perc</option>
                  <option value={15}>15 perc</option>
                  <option value={30}>30 perc</option>
                  <option value={45}>45 perc</option>
              </select>
            </div>

            <hr />
            <div>Létszám</div>

            <input
              type='text'
              className='w-full focus:outline-none font-medium px-4 py-3 border border-t-ccc bg-t-light flex items-center gap-2'
              placeholder="Létszám"
              value={headcount}
              onChange={(e) => handleDigitInput(e, 2, setHeadcount, 100)}
            />
            
          {/* <label className="cursor-pointer label justify-start w-fit mt-8">
            <input type="checkbox" 
              className="checkbox border-t-dark bg-t-light mr-2"
              // checked={fixDate} 
              // onChange={e => setFixDate(e.target.checked)}
            />
            <span className="label-text text-t-dark text-lg">Fix dátum megadása.</span>
          </label> */}

            <hr />
            <div>Típus</div>

            <div className="w-full justify-center rounded-none">
              <select
                className="select w-full focus:outline-none rounded-none border border-t-ccc bg-t-light"
                value={dateType || "type"}
                onChange={(e) => setDateType(e.target.value)}
              >
                <option value="type">Típus</option>
                <option value="onetime">Egyedi</option>
                <option value="recurring">Ismétlődő</option>
                {/* <option value="free">Szabad dátum</option> */}
              </select>
            </div>


          {dateType === "recurring" &&
          <>
            <div className="w-full justify-center rounded-none">
            <div className='my-2'>Milyen napokon lesz?</div>
              <ul className="grid w-full gap-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-7">
                {Object.entries(recurringDays).map(([dayCode, checked]) => (
                    <li key={`${event.id}-${dayCode}`}>
                        <input type="checkbox" id={`${event.id}-${dayCode}`} name={dayCode}
                          checked={checked}
                          onChange={handleRecurringDaysChange} className="hidden peer"/>
                        <label htmlFor={`${event.id}-${dayCode}`} className="inline-flex items-center justify-center w-full p-2 text-t-dark bg-t-gray border-2 border-t-ccc rounded-lg cursor-pointer peer-checked:border-t-dark peer-checked:bg-t-blue peer-checked:text-t-dark hover:text-t-light hover:bg-t-dark">                           
                            <div className="block">
                                {getRecurringDayLabel(dayCode)}
                            </div>
                        </label>
                    </li>
                ))}
                </ul>
            </div>
            <div className="flex w-full mb-4 bg-white">
              <div>
                <div className='my-2'>Mikor kezdődik?</div>
                  <div className="join rounded-none relative flex items-center w-1/2 border border-t-ccc">
                    <input
                      type="text"
                      value={startHour}
                      onChange={handleStartHourChange}
                      onBlur={handleBlurStartHour}
                      placeholder="00"
                      className="text-right focus:outline-none w-full p-2 bg-t-light"
                    />
                    <span>:</span>
                    <input
                        type="text"
                        value={startMinute}
                        onChange={handleStartMinuteChange}
                        onBlur={handleBlurStartMinute}
                        placeholder="00"
                        className="text-left focus:outline-none w-full p-2 bg-t-light"
                    />
                  </div>
              </div>
            </div>
          </>
          }
            <div>
                {dateType === 'onetime' && (
                    <>
                    {eventDates?.length !== 0 ? (
                        eventDates.map((eventDate) => (
                            <EventDatesBlock
                                key={eventDate.uuid} // Use the unique uuid as the key
                                eventId={event?.id}
                                eventDate={eventDate}
                                updateEventDate={updateEventDate}
                                removeEventDate={removeEventDate}
                            />
                        ))
                    ) : (
                        <p className="text-center text-lg mt-4">Legalább egy dátumot meg kell adnod.</p>
                    )}

                <div className='flex mt-4 w-full items-center justify-end'>
                    <button
                        className="btn bg-t-green btn-outline mt-4 hover:bg-t-green/75"
                        onClick={addEventDate}
                    >
                        Új dátum hozzáadása
                    </button>
                </div>
                </>
                )}
            </div>


          {/* {dateType === "free" &&
            <div className="w-full justify-center rounded-none">

            </div>
          }           */}
          <hr />
          <div className="card-actions justify-end h-16">
            <div className='absolute bottom-4 right-4'>
              <div className='modal-action'>
                <button
                    className="btn bg-t-pink btn-outline mt-4 hover:bg-t-pink/75"
                    onClick={() => handleClose()}
                >
                    Bezár
                </button>
                <button
                    className="btn bg-t-green btn-outline mt-4 hover:bg-t-green/75"
                    onClick={() => handleSave()}
                >
                    Mentés
                </button>
                <button
                    className="btn bg-t-green btn-outline mt-4 hover:bg-t-green/75"
                    onClick={() => {handleSave(); handleClose()}}
                >
                    Mentés és bezárás
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
      </div>
      </div>
</>
  );
}

