import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // To manage cookies
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminEmailEditor({ userId, identifier }) {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [email, setEmail] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [password, setPassword] = useState('');

  const [inputErrors, setInputErrors] = useState(
      {
          email: false,
          password: false
      }
  );


  const [firstname, setFirstname] = useState('');
  const [originalFirstname, setOriginalFirstname] = useState('');

  const [lastname, setLastname] = useState('');
  const [originalLastname, setOriginalLastname] = useState('');

  const [newsletter, setNewsletter] = useState(false);
  const [originalNewsletter, setOriginalNewsletter] = useState(false);

  const [loading, setLoading] = useState(true);

  const labelClass = "focus:outline-none font-medium p-2 border bg-t-light w-full flex items-center gap-2"

  useEffect(() => {
    getProfileEmail();
  }, []);

  const getProfileEmail = async () => {

    const fieldData = {
      userId,
      identifier
    };

    fetch(`${apiBaseUrl}api/email/select`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
      },
      body: JSON.stringify(fieldData),
    })
    .then(response => {
      if (response.status === 404) {
        // handleLogout();
      }
      return response.json();
    })
    .then(data => {
      if (data) {
        setEmail(data?.email);
        setOriginalEmail(data?.email);
      }
      setLoading(false);
    })
    .catch(error => {
      console.error('Error:', error);
      setLoading(false);
    });
  }

  const isError = async () => {
    let hasError = false;

    if (!email) {
        if(!hasError) {
            toast.error('Meg kell adnod az e-mail címed.');
        }
        setInputErrors(prevState => ({
            ...prevState,
            email: true,
        }));
        hasError = true;
    }

    if(!password) {
        if(!hasError) {
            toast.error('Meg kell adnod a jelszavadat.');
        }
        setInputErrors(prevState => ({
            ...prevState,
            password: true,
        }));
        hasError = true;
    }

    return hasError;
};

  const handleLogout = () => {
    Cookies.remove('uuid');
    Cookies.remove('firstname');
    window.location.reload();
  };

  const updateEmail = async () => {

    setInputErrors({
      email: false
    });

    setLoading(true);

    if(await isError()) {
        setLoading(false);
        return;
    }

    const emailData = {
      userId,
      identifier,
      originalEmail,
      email,
      password
    };

    fetch(`${apiBaseUrl}api/email/update`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
      },
      body: JSON.stringify(emailData),
    })
    .then(response => {
      setPassword('');
      if (response.status === 500) {
        setLoading(false);
      // handleLogout();
      }
      return response.json();
    })
    .then(data => {
      toast.success('Sikeres mentés.');
      setOriginalEmail(email);
      setPassword('');
      setLoading(false);
    })
    .catch(error => {
      console.error('Error updating email:', error);
      setPassword('');
      setLoading(false);
    });
  }

  if (loading) {
    return (
        <div className='flex justify-center min-h-50-screen'>
            <span className="loading loading-spinner loading-lg"></span>
        </div>
    );
}

  return (
    <>
      <div className="collapse bg-base-200 rounded-lg mb-2 border-2 border-t-dark">
        <input type="checkbox" defaultChecked disabled style={{ cursor: 'default'}}/>
        <div className="collapse-title text-xl font-medium bg-t-beige">E-mail cím</div>
        <div className="collapse-content">
          <div className="label mt-8">
              <div className='label-text'>Ezzel az e-mail címmel tudsz bejelentkezni.</div>
          </div>
          <label className={`${labelClass} ${inputErrors.email ? 'border-red-500' : ''}`}>
            E-mail
            {inputErrors.email && <span className="indicator-item badge badge-sm bg-red-500 text-t-light">!</span>}
            <input type="email" className="grow focus:outline-none "
                placeholder="hello@timely.hu"
                value={email} 
                onChange={e => setEmail(e.target.value)} 
            />
          </label>
          {(email !== originalEmail) &&
          <>
            <div className="label mt-4">
              <div className='label-text'>Ha meg szeretnéd változtatni az e-mail címed, meg kell adnod a jelszavadat.</div>
            </div>
            <label className={`${labelClass} ${inputErrors.password ? 'border-red-500' : ''}`}>
              Jelszó
              <input type="password" className="grow focus:outline-none "
                placeholder="********"
                value={password} 
                onChange={(e) => setPassword(e.target.value)}
                />
            </label>
          </>
          }
          
          <div className='text-right'>
            <button className='btn bg-t-green btn-outline mt-4 hover:bg-t-green/75' onClick={updateEmail}
              disabled={loading || (email === originalEmail)}
            >
              Mentés
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminEmailEditor;
